<template>
  <div>
    <a target="_blank" class="btn text-white py-3 px-5 textresize" :href="link">
      {{text}}
    </a>
  </div>
</template>

<script>
  export default {
    name: "ConsultationButton",
    props: {
      text: {
        default:'Schedule Consulation Now',
        type:String
      },
      link: {
        default:'https://www.immigrationlaw.ai/activity/ailaw/consultation',
        type:String
      }
    }
  }
</script>

<style scoped>
  a {
    background-image: linear-gradient(to bottom right, #F9B54A, #F76B1C);
    transition: all .3s linear;
  }
  a:hover {
    background-image: linear-gradient(to bottom right, #F76B1C, #F9B54A);
  }

  @media only screen and (max-width: 576px) {
    .textresize {
      font-size: 0.75rem;
      padding: 0.75rem 1.0rem !important;
    }
  }
</style>
