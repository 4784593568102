<template>
  <div class="h row fstart">
    <div class="font-weight-bolder slogan">
      <slot name="slogan"></slot>
    </div>
    <div class="slogan-text">
      <slot name="slogan-text"></slot>
    </div>
    <div class="py-4">
      <slot name="addon"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Splash',
}
</script>

<style scoped lang="scss">
.fstart {
  justify-content: flex-start;
}
.slogan {
  font-size: 2.5rem;
}

.slogan-text {
  font-size: 1.125rem;
  font-style: italic;
  font-weight: lighter;
}

@keyframes popin {
  0% {
    opacity: 0.2;
    position: relative;
    top: 10px;
  }
  100% {
    opacity: 1;
    position: relative;
    top: 0;
  }
}

.slogan {
  animation-name: popin;
  animation-duration: 1s;
}

@keyframes popin-slow {
  0% {
    opacity: 0;
    position: relative;
    top: 10px;
  }
  50% {
    opacity: 0;
    position: relative;
    top: 10px;
  }
  100% {
    opacity: 1;
    position: relative;
    top: 0;
  }
}

.slogan-text {
  animation-name: popin-slow;
  animation-duration: 2s;
}
@media (max-width: 1100px) {
  div.slogan,
  div.py-4 {
    margin: 0 auto;
  }
}
@media screen and (max-width: 576px) {
  .slogan {
    font-size: 1.45rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .slogan-text {
    font-size: 0.95rem;
    padding: 0rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
@media (max-width: 850px) {
  div.slogan-text {
    font-size: 14px;
  }
  div.fstart {
    justify-content: center;
    text-align: center;
  }
}
@media (max-width: 700px) {
  div.slogan-text {
    font-size: 12px;
  }
}
@include media-breakpoint-up(lg) {
  .slogan {
    font-size: 3rem;
  }

  .slogan2 {
    font-size: 3.125rem;
  }

  .slogan-text {
    font-size: 1.25rem;
  }

  .slogan-main {
    line-height: 1.75rem;
  }
}
</style>
